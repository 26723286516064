<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <b-row>
              <b-col md="10"><h5> Modulo de Mesas</h5></b-col>
              <b-col md="2"><b-button type="button" @click="ModalNewTableShow" class="form-control" variant="primary">NUEVA MESA</b-button></b-col>
            </b-row>
          </CCardHeader>
          <CCardBody>
            <b-tabs content-class="mt-3" align="center">
              <b-tab v-for="(room, it1) in rooms" :key="it1" :title="room.name" :active="it1 == 0" >

                    <grid-layout :layout.sync="room.tables"
                                :col-num="colNum"
                                :row-height="30"
                                :is-draggable="draggable"
                                :is-resizable="resizable"
                                :vertical-compact="true"
                                :use-css-transforms="true"
                            >
                            <grid-item v-for="(item,it2 ) in room.tables" :key="it2" 
                                  :static="item.static"
                                  :x="item.x"
                                  :y="item.y"
                                  :w="item.w"
                                  :h="item.h"
                                  :i="item.i"
                                  @move="MoveTable"
                                  @resize="ResizeTable"
                                  :class="Background(item.static,item.state)"
                              >
                            <span class="text">{{item.i}}</span>
                            <span class="edit" @click="ModalEditTableShow(item.id_table)"><i class="fas fa-edit"></i></span>
                            <span class="remove" @click="RemoveTable(it1,item.i)"><i class="fas fa-trash-alt"></i></span>
                            
                        </grid-item>
                    </grid-layout>
           
              </b-tab>
     
            </b-tabs>

  
          </CCardBody>
        </CCard>
      </CCol>
  
      <NewTable />
      <EditTable />
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
import { GridLayout, GridItem } from "vue-grid-layout"
// https://jbaysolutions.github.io/vue-grid-layout/guide/01-basic.html
import NewTable from './components/ModalNewTable'
import EditTable from './components/ModalEditTable'
export default {
  name: "UsuarioList",
  components: {
    GridLayout,
    GridItem,
    NewTable,
    EditTable,
  },
  data() {
    return {
      module:'Table',
      rooms:[],
      eventLog: [],

      

      draggable: true,
      resizable: true,
      colNum: 30,
      index: 0,


      ///nuevo
      table:{
        rooms:[],
        id_room:'',
        name:'',
        description:'',
        x:'',
        y:'',
        w:'',
        h:'',
        i:'',
        static: true,
        state:'1',
      },
      state:[
        {value:1,text:'Activo'},
        {value:0,text:'Inactivo'},
      ],
      errors:{
        id_room:false,
        name:false,

      }




    };
  },

  mounted() {
    EventBus.$on('RefreshListTable', () => {
      this.ListTable();
    });
    this.ListTable();
  },
  methods: {
    Background,
    Permission,
    ListTable,

    //nuevo
    ModalNewTableShow,
    ModalEditTableShow,
    MoveTable,
    ResizeTable,
    RemoveTable,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function Background(type,state) {
  if (type == 1) {
    return 'bg-default';
  }else{
    if (state == 1) {
      return 'bg-success';
    }else{
      return 'bg-warning';
    }
  }
}

function ListTable() {
  let me = this;
  let url = this.url_base + "table/list";
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: 1},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.rooms = response.data.result;
    } else {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
  });
}

function ModalNewTableShow() {
   EventBus.$emit('ModalNewTableShow');
}

function ModalEditTableShow(id_table) {
   EventBus.$emit('ModalEditTableShow',id_table);
}

function MoveTable(i, newX, newY) {
  // const msg = "MOVENEW i=" + i + ", X=" + newX + ", Y=" + newY;
  // console.log(msg);
  let me = this;
  let url = me.url_base + "table/move";
  let data = {
    i:i,
    x:newX,
    y:newY,
  };
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: 3},
  })
  .then(function (response) {
    if (response.data.status == 200) {

    } 
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error en la gra', timer: 3000,})
  });
}

function ResizeTable(i, newH, newW, newHPx, newWPx) {
  // const msg = "RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
  // console.log(msg);
  let me = this;
  let url = me.url_base + "table/resize";
  let data = {
    i:i,
    h:newH,
    w:newW,
  };
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: 3},
  })
  .then(function (response) {
    if (response.data.status == 200) {

    } 
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error en la gra', timer: 3000,})
  });
}

function RemoveTable(index,val) {

  let me = this;
  let url = me.url_base + "table/delete";
  let data = {
    i:val,
  };
  axios({
    method: "put",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: 3},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      const indextable = me.rooms[index].tables.map(item => item.i).indexOf(val);
      me.rooms[index].tables.splice(indextable, 1);
    } 
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error en la gra', timer: 3000,})
  });

}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
<style scoped>
.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}
.columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
/*************************************/
.remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
    color: blue;
}
.edit {
    position: absolute;
    left: 2px;
    top: 0;
    cursor: pointer;
    color: blue;
}
.vue-grid-layout {
    background: #E4ECF9;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: #80A8FF;
    border: 1px solid #0182FF;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

</style>